import React from "react"
import { SiteSiteMetadataAuthor } from "graphql-types"
import styled from "styled-components"

import FlipbaseButton from "../../FlipbaseButton"
import FlipbaseIcons from "../../DecorationElements/FlipbaseIcons"

import FlipbaseCard from "../../FlipbaseCard"
import { Col, Row, Modal } from "antd"
import { device, colors } from "../../../styles/constants"
import IFrameVideo from "../../IFrameVideo"

class ModalCareersComponent extends React.Component {
  state = { visible: false }

  showModal = () => {
    if (this.props.data.sub_type === undefined) {
      this.setState({
        visible: true,
      })
    }
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }
  render() {
    let titleModal = this.props.data.title

    return (
      <>
        <StyledCol xl={6} lg={12} md={12} sm={12} xs={24}>
          <CardWrapper onClick={this.showModal}>
            <FlipbaseCard
              type="Icon"
              maxWidth="347px"
              data={this.props.data}
              toRoute={this.props.data.modalData.ctaLink}
              backgroundColor="rgba(166,38,81,0.1)"
            />
          </CardWrapper>
          <StyledModal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            className="resp_modal"
            centered
            footer={[]}
            width={960}
          >
            <ModalBody>
              <StyledRow gutter={24}>
                <StyledCol xl={13} lg={13} md={24} sm={24} xs={24}>
                  <TitleJob>{titleModal}</TitleJob>
                  <DescriptionJob>
                    {this.props.data.modalData.descriptionFirst}
                  </DescriptionJob>
                  <JobInfo>
                    <RateWrapper>
                      <FlipbaseIcons type="euro" />
                      <InfoWrapper>
                        <div>
                          <InfoText>
                            &euro;{this.props.data.modalData.hourlyRate}
                          </InfoText>{" "}
                          / h
                        </div>
                        <p>Depending on experience</p>
                      </InfoWrapper>
                    </RateWrapper>
                    <TimeWorkWrapper>
                      <FlipbaseIcons type="hourly" />
                      <InfoWrapper>
                        <div>
                          <InfoText>
                            {this.props.data.modalData.timeWork}
                          </InfoText>{" "}
                          / a week
                        </div>
                        <p>
                          {this.props.data.modalData.partTime
                            ? "Part time"
                            : "Full time"}
                        </p>
                      </InfoWrapper>
                    </TimeWorkWrapper>
                    <StartingDateWrapper>
                      <FlipbaseIcons type="calendar" />
                      <InfoWrapper>
                        <div>
                          <InfoText>
                            {this.props.data.modalData.startingDate}
                          </InfoText>{" "}
                        </div>
                        <p>or in consultation</p>
                      </InfoWrapper>
                    </StartingDateWrapper>
                    <ButtonAplyWrapper>
                      <FlipbaseButton
                        active={this.props.data.open}
                        fullWidth={true}
                        type={this.props.data.open ? "primary" : "secondary"}
                        toRoute={this.props.data.modalData.ctaLink}
                      >
                        {this.props.data.open ? "Apply now" : "Opening soon"}
                      </FlipbaseButton>
                    </ButtonAplyWrapper>
                  </JobInfo>
                </StyledCol>
                <StyledCol xl={11} lg={11} md={24} sm={24} xs={24}>
                  <WrapperFrame>
                    <IFrameVideo
                      videoSrc={this.props.data.modalData.videoSrc}
                    />
                  </WrapperFrame>
                </StyledCol>
              </StyledRow>
              <StyledRow>
                <StyledColBorder xl={12} lg={12} md={12} sm={24} xs={24}>
                  <TitleWork>Your Profile: </TitleWork>
                  <ListCareers>
                    {this.props.data.modalData.profile.map((item, index) => {
                      return <li key={index}>{item}</li>
                    })}
                  </ListCareers>
                </StyledColBorder>
                <StyledColBorder xl={12} lg={12} md={12} sm={24} xs={24}>
                  <TitleWork>We Offer: </TitleWork>
                  <ListCareers>
                    {this.props.data.modalData.offer.map((item, index) => {
                      return <li key={index}>{item}</li>
                    })}
                  </ListCareers>
                </StyledColBorder>
              </StyledRow>
            </ModalBody>
          </StyledModal>
        </StyledCol>
      </>
    )
  }
}

const StyledCol = styled(Col)`
  @media ${device.laptopL} {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    margin: 32px 0;
  }
  @media ${device.tablet} {
    margin-bottom: 16px;
    div {
      div {
        margin: 0 auto;
      }
    }
  }
  @media ${device.mobileL} {
    padding: 0 !important;
    margin-bottom: 16px;
    :nth-of-type(4) {
      margin-top: 16px;
    }
  }
`
const StyledColBorder = styled(Col)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 36px;
  @media ${device.mobileL} {
    padding: 24px 0 0 !important;
    :nth-of-type(2) {
      border-top: none;
    }
  }
`

const StyledRow = styled(Row)`
  width: 100%;
  padding: 40px;

  @media ${device.laptopL} {
    padding: 12px 24px;
  }
  @media ${device.tabletL} {
    justify-content: space-between;
  }
  @media ${device.mobileL} {
    justify-content: center;
    margin: 0 !important;
    padding: 16px 24px;
  }
`

const StyledModal = styled(Modal)`
  padding-bottom: 0px !important;
  .ant-modal-close-x {
    color: #a62651;
  }
  @media ${device.laptopL} {
    max-width: calc(100vw - 32px) !important;
  }
  @media ${device.mobileL} {
    padding-bottom: 96px !important;
  }
  .ant-modal-body {
    width: 100%;
    height: auto;
    padding: 8px !important;
    overflow-y: scroll;
    @media (max-width: 575px) {
      padding: 40px 16px 0;
    }
  }
  .ant-modal-footer {
    padding: 0;
  }
  .ant-modal-wrap {
    overflow: hidden !important;
  }
`
const ModalBody = styled.div`
  padding: 0;
`

const CardWrapper = styled.div`
  height: 100%;

  div @media(max-width:750px) {
    padding: 12px 24px;
  }
`

const TitleWork = styled.h4`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.dark};
`

const JobInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  min-height: 120px;
  box-sizing: border-box;
  padding-top: 18px;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media ${device.tablet} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 0;
  }
  @media ${device.mobileL} {
    height: 180px;
    justify-content: flex-start;
    border-bottom: none;
    a {
      width: 100%;
      div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`

const TitleJob = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  color: ${colors.dark};
  @media ${device.laptopL} {
    font-size: 34px;
    line-height: 38px;
  }
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 31px;
  }
  @media ${device.mobileL} {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 21px;
  }
`

const DescriptionJob = styled.p`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(35, 29, 48, 0.6);
  margin-bottom: 36px;
  @media ${device.tablet} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 23px;
  }
`
const RateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 16px;
  }
`

const ButtonAplyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin-top: 16px;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 16px;
  }
`

const TimeWorkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 16px;
  }
`

const StartingDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin-top: 16px;
  @media ${device.mobileL} {
    flex-direction: column;
    align-items: start;
    margin-bottom: 16px;
  }
`

const InfoText = styled.span`
  font-family: Poppins;
  font-weight: 600;
  font-size: 21px;
  line-height: 23px;
  color: ${colors.dark};
  @media ${device.laptopL} {
    font-size: 19px;
    line-height: 21px;
  }
  @media ${device.tablet} {
    font-size: 19px;
    line-height: 21px;
  }
  @media ${device.mobileL} {
    font-size: 17px;
    line-height: 19px;
  }
`
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 12px;
  div,
  p {
    font-family: FreightSans;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(35, 29, 48, 0.6);
    margin-bottom: 0;
  }
  p {
    font-size: 10px;
    line-height: 15px;
  }
  @media ${device.mobileL} {
    margin-left: 0px;
    margin-top: 12px;
  }
`
const ListCareers = styled.ul`
  list-style-image: url(/elipse.png);
  ::marker {
    height: 4px !important;
  }
  li {
    font-family: FreightSans;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: rgba(35, 29, 48, 0.6);
    margin-bottom: 0;
    max-width: 95%;

    @media ${device.laptopL} {
      font-size: 15px;
      line-height: 20px;
    }
  }
`

const WrapperFrame = styled.div`
  margin-top: 43px;
`

export default ModalCareersComponent
