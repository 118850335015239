import React, { useState, useEffect } from "react"
import TitleDescriptionGeneral from "../../TitleDescritptionGeneral"

// const titleDescriptionAbout = {
//     title: "Working at Flipbase",
//     descriptionFirst: "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology.",
//     descriptionSecond: "In our company we value self development, having freedom, being an entrepreneur and working together as a family."
// }

const titleDescriptionAbout = {
  title: "Working at Flipbase",
  descriptionFirst:
    "In our company we value self development, having freedom, being an entrepreneur and working together as a family.",
  descriptionSecond:
    "Besides having such great colleagues, we offer a lot of freedom (a lot!) and flexibility. You can be the best version of yourself and you shouldn't be afraid to make any mistakes.",
}

/* Title Description About component from the page about us */

class TitleDescriptionCareers extends React.Component {
  render() {
    return <TitleDescriptionGeneral data={titleDescriptionAbout} xlSize={13} />
  }
}

export default TitleDescriptionCareers
