import React from "react"
import styled from "styled-components"
import { device, colors } from "../../styles/constants"
import { Col } from "antd"

/* Simple Title Description */

interface TitleDescriptionGeneral {
  data: Record<string, any>
  xlSize: number
}

const TitleDescriptionGeneral: React.FunctionComponent<
  TitleDescriptionGeneral
> = ({ data, xlSize }): React.ReactElement => {
  return (
    <Col xl={xlSize} lg={10} md={24}>
      <Title>{data.title}</Title>
      <Description>{data.descriptionFirst}</Description>
      <Description>{data.descriptionSecond}</Description>
    </Col>
  )
}

const Title = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: ${colors.dark};
  @media ${device.laptopL} {
    font-size: 34px;
    line-height: 38px;
  }
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 31px;
    margin-top:24px;
  }
  @media ${device.mobileL} {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 21px;
  }
`

const Description = styled.p`
  font-family: FreightSans;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(35, 29, 48, 0.6);

  @media ${device.tablet} {
    font-size: 22px;
    line-height: 28px;
  }
  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 23px;
  }
`

export default TitleDescriptionGeneral
