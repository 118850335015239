import React from "react"
import { GatsbyLocation } from "local-types"
import styled from "styled-components"
import { Col } from "antd"
import { device } from "../styles/constants"
import Layout from "../components/Layout"
import { useSiteMetadata } from "../hooks"
import SectionLayout from "../components/WrapperSections/SectionLayout"
import SEO from "../components/Seo/Seo"

import TitleDescriptionCareers from "../components/CareersComponents/TitleDescriptionCareers"
import CardRowCareers from "../components/CareersComponents/CardsRowCareers"
import IFrameVideo from "../components/IFrameVideo"
import FooterContact from "../components/ContactComponents/FooterContact"



interface CareersProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
}

const careersMetadata = {
  Title: "Careers - Flipbase",
  Keywords: ["Flipbase careers", "Flipbase vacancies", "Flipbase jobs"],
  Description:
    "Do you want to work at Flipbase? Check out our current vacancies and apply now!",
  Image: "/seo-image-general.png",
}

const CareersPage: React.FunctionComponent<CareersProps> = ({
  location,
  preivousPath = "/AboutUs",
  nextPath = "/blog",
}): React.ReactElement => {
  const { title: siteTitle, author } = useSiteMetadata()

  const isBrowser = typeof window !== `undefined`
  let translatePage

  if (location.state !== null && isBrowser) {
    if (location.state.prevPath != "/AboutUs") {
      translatePage = -100
    } else {
      translatePage = 100
    }
  }

  return (
    <Layout
      location={location}
      title={siteTitle}
      preivousPath={preivousPath}
      nextPath={nextPath}
    >
      <SEO
        title={careersMetadata.Title}
        description={careersMetadata.Description}
        image={careersMetadata.Image}
        keywords={careersMetadata.Keywords}
      />
      <SectionLayout>
        <ContentWrapper>
          <TitleDescriptionCareers />
          <StyledCol xxl={10} xl={9} lg={9} md={23} sm={24} xs={24}>
            <IFrameVideo videoSrc="https://app.flipbase.com/embed/5120293b-583b-4534-90dc-0f44cd51705e" />
          </StyledCol>
          <CardRowCareers />
        </ContentWrapper>
      </SectionLayout>
      <FooterContact paddingLaptop="20px 120px 20px 120px;" elPosition="relative"  />
    </Layout>
  )
}

export const ContentWrapper = styled.div`
  padding-top: 96px;
  margin-bottom:64px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${device.laptopL} {
    padding-top: 36px;
  }
  @media ${device.tablet} {
    margin: 0 30px;
  }
  @media ${device.mobileL} {
    margin: 0 16px;
  }
`
export const StyledCol = styled(Col)`
  @media ${device.laptopL} {
    margin-right: 24px !important;
  }
`

export default CareersPage
