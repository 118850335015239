import React from "react"
import styled from "styled-components"
import ModalCareersComponent from "../ModalCareersComponent"

import { Row } from "antd"
import { device, colors } from "../../../styles/constants"

/* Card Row from the page careers */

const CardsRowData = [
  {
    title: "Full-stack developer",
    description: " Do you want to build cutting edge video technology?",
    icon: "full-stack",
    cta: "Learn more",
    open: false,
    modalData: {
      title: "Development Team",
      descriptionFirst:
        "We are a video technology company with tme mentality of a pioneer, always trying to be a head of the market and constantly searching and developing new technology.",
      videoSrc:
        "https://app.flipbase.com/embed/5120293b-583b-4534-90dc-0f44cd51705e",
      hourlyRate: "20-25",
      timeWork: "40h",
      partTime: false,
      startingDate: "February 1st, 2021",
      ctaLink: "https://flipbase.varbi.com/en/apply/positionquick/348498/",
      profile: [
        "You are available 5 days a week(40 hours);",
        "You have at least 1 year of experience with React;",
        "You have at least 1 year of experience with any tier 1 cloud provider;",
        "You have at least 2 years of experience with Node.js (we use Express);",
        "You have at least 3 years of experience as a software developer;",
        "You have experience with designing REST and GraphQL API's;",
        "You have experience with Docker;",
        "You have experience with CI/CD;",
        "Nice to have experience with: Ansible/Terraform or DevOps, AWS, MongoDB.",
      ],
      offer: [
        "Growth opportunities;",
        "Flexible working hours;",
        "A look into the (development) kitchen of a fast-growing startup;",
        "An open working environment with room to make mistakes, learn and grow naturally;",
        "Fun outings such as going to a festival, sailing a boat or to the sun;",
        "Cozy free mibo in and outside the office.",
      ],
    },
  },
  //   Behavioral/Cultural Fit:
  // - Self starter
  // - Creativity
  // - Pragmatic and flexibility person
  // - Get things done personality
  // - Ability to learn technologies on the fly within the project deadline
  {
    title: "Growth specialist",
    description:
      "Do you want to convince recruitment professionals to communicate more with video?",
    icon: "growth-spec",
    cta: "Learn more",
    open: false,
    modalData: {
      title: "Development Team",
      descriptionFirst:
        "We are a video technology company with tme mentality of a pioneer, always trying to be a head of the market and constantly searching and developing new technology.",
      videoSrc:
        "https://app.flipbase.com/embed/1252a7da-4bf2-46a6-9139-7ca25d24f9d2",
      hourlyRate: "9-12",
      timeWork: "24-32h",
      partTime: true,
      startingDate: "February 1st, 2021",
      ctaLink: "https://flipbase.varbi.com/en/apply/positionquick/292696/",
      profile: [
        "You are eager to learn and ambitious;",
        "You are available 2-5 days a week;",
        "You are doing your HBO or WO studies or already have a bachelor / master in your pocket;",
        "You are not afraid to approach people and you enjoy enthusing people",
        "You are a self-starter who likes to think along and care to come up with ideas.",
      ],
      offer: [
        "Growth opportunities. For example, you have the option to do your graduation assignment with us. In addition, we want to give you the opportunity to grow into a full-time member of the team!",
        "Flexible working hours in the form of a fixed or 0-hour contract. With a salary of € 9-12 gross per hour;",
        "A look into the (sales) kitchen of a fast-growing startup;",
        "An open working environment with room to make mistakes, learn and grow naturally;",
        "Fun outings such as going to a festival, sailing a boat or to the sun;",
        "Cozy free mibo in and outside the office.",
      ],
    },
  },
  {
    title: "Content marketeer",
    description:
      "Do you want to make high end  (video) content that attracts recruitment professionals?",
    icon: "content-marketer",
    cta: "Learn more",
    open: false,
    modalData: {
      title: "Commercial Team",
      descriptionFirst:
        "We are a video technology company with tme mentality of a pioneer, always trying to be a head of the market and constantly searching and developing new technology.",
      videoSrc:
        "https://app.flipbase.com/embed/5120293b-583b-4534-90dc-0f44cd51705e",
      hourlyRate: "10-15",
      timeWork: "16-24h",
      partTime: true,
      startingDate: "May 1st, 2021",
      ctaLink: "https://flipbase.varbi.com/en/apply/positionquick/346453/",
      profile: [
        "You are eager to learn and ambitious;",
        "You are available 2-5 days a week;",
        "You have affinity with video and video content;",
        "You are innovative and a storyteller;",
        "You are a creative person with basic knowledge of SEO and Google Analytics;",
        "You are doing your HBO or WO studies or already have a bachelor / master in your pocket;",
        "You are a self-starter who likes to think along and care to come up with ideas.",
      ],
      offer: [
        "Growth opportunities. For example, you have the option to do your graduation assignment with us. In addition, we want to give you the opportunity to grow into a full-time member of the team!",
        "Flexible working hours in the form of a fixed or 0-hour contract. With a salary of € 9-12 gross per hour;",
        "A look into the (sales) kitchen of a fast-growing startup;",
        "An open working environment with room to make mistakes, learn and grow naturally;",
        "Fun outings such as going to a festival, sailing a boat or to the sun;",
        "Cozy free mibo in and outside the office.",
      ],
    },
  },
  {
    subhead: "We are always looking for talent",
    imageCover: "/talent.svg",
    sub_type: "talent_search",
    cta: "Open application",
    open: true,
    modalData: {
      title: "Open application",
      descriptionFirst:
        "We are a video technology company with tme mentality of a pioneer, always trying to be a head of the market and constantly searching and developing new technology.",
      videoSrc:
        "https://app.flipbase.com/embed/5120293b-583b-4534-90dc-0f44cd51705e",
      hourlyRate: "18-20",
      timeWork: "8-12h",
      partTime: true,
      startingDate: "February 1st, 2021",
      ctaLink: "https://flipbase.varbi.com/en/apply/positionquick/342689",
      profile: [
        "You are eager to learn and ambitious;",
        "You are available 2-5 days a week(16-32 hours);",
        "You are doing your HBO or WO studies or already have a bachelor / master in your pocket;",
        "You are not afraid to approach people and you enjoy enthusing people",
        "You are a self-starter who likes to think along and care to come up with ideas.",
      ],
      offer: [
        "Growth opportunities. For example, you have the option to do your graduation assignment with us. In addition, we want to give you the opportunity to grow into a full-time member of the team!",
        "Flexible working hours in the form of a fixed or 0-hour contract. With a salary of € 9-12 gross per hour;",
        "A look into the (sales) kitchen of a fast-growing startup;",
        "An open working environment with room to make mistakes, learn and grow naturally;",
        "Fun outings such as going to a festival, sailing a boat or to the sun;",
        "Cozy free mibo in and outside the office.",
      ],
    },
  },
]

class CardsRowCareers extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Title>Open jobs</Title>
        <StyledRow gutter={24} justify="space-between">
          {CardsRowData.map(card => {
            return <ModalCareersComponent data={card} />
          })}
        </StyledRow>
      </ContentWrapper>
    )
  }
}

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`

const Title = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  color: ${colors.dark};
  margin-bottom: 16px;
  @media ${device.laptopL} {
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 24px;
  }
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 31px;
  }
  @media (max-width: ) {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 21px;
  }
  @media ${device.mobileL} {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 21px;
  }
`

const StyledRow = styled(Row)`
  width: 100%;
  @media ${device.mobileL} {
    margin: 0 0 32px !important;
  }
`

export default CardsRowCareers
